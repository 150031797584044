import {queryParams} from './http'

export default class userService {
  get() {
    return fetch('/api/user/get', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => response.json())
  }

  getUsersByBuildingId(buildingId) {
    return fetch(`/api/user/getUsersByBuildingId?buildingId=${buildingId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  inviteExcel(institutionId, institutionBuildingIds, file, reset){
    return fetch(`/api/user/inviteExcel?${queryParams({ institutionId, institutionBuildingIds, reset })}&institutionBuildingIds=`, {
      method: 'POST',
      mode: 'cors', 
      credentials: 'include',
      body: file,
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 

    }) 
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }
  
  inviteList(invites, institutionId,institutionBuildingIds, training, userManagement, threatTeam, viewSelfHarm, viewBully, viewAllThreats, reviewThreats, emergencyPlan, riskSurvey, reset ){
    return fetch('/api/user/inviteList', { 
      method: 'POST', 
      body: JSON.stringify({ invites, institutionId, institutionBuildingIds, training, userManagement, threatTeam, viewSelfHarm, viewBully, viewAllThreats, reviewThreats, emergencyPlan, riskSurvey, reset }), 
      mode: 'cors', 
      credentials: 'include', 
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  inviteUser(user){
    return fetch('/api/user/inviteUser', { 
      method: 'POST', 
      body: JSON.stringify(user), 
      mode: 'cors', 
      credentials: 'include', 
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  update(user){
    return fetch('/api/user/update', { 
      method: 'POST', 
      body: JSON.stringify(user), 
      mode: 'cors', 
      credentials: 'include', 
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json()
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  reInvite(email){
    return fetch('/api/user/reInvite', { 
      method: 'POST', 
      body: JSON.stringify({ email }), 
      mode: 'cors', 
      credentials: 'include', 
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  delete(userId, institutionBuildingId){
    return fetch('/api/user/delete', { 
      method: 'POST', 
      body: JSON.stringify({userId, institutionBuildingId}), 
      mode: 'cors', 
      credentials: 'include', 
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  downloadCertificate(userId){
    window.open(`https://training.clpsconsultants.com/api/user/downloadCertificate?userId=${userId}`) 
  }

}